import React, {Component, createElement} from 'react';
import { Base64 } from 'js-base64';
import { InnerBlocks } from '@wordpress/editor';
import { __ } from '@wordpress/i18n';
import { createBlock } from '@wordpress/blocks';
import BlockEditor, { edit, save } from './BlockEditor';
import { smartfireIcon } from './icon';

/**
 * @deprecated
 */
export default class SmartfireComponent extends Component {
  static classSupport = true;
  static smartProps = [];

  constructor(props) {
    super(props);
  }

  setAttributesHandler = null;

  get isEditing() {
    if (typeof this.props.editing == 'boolean') {
      return this.props.editing;
    } else {
      return false;
    }
  }

  setAttributes(attributes) {
    if (this.isEditing && this.props.setAttributesHandler != null) {
      return this.props.setAttributesHandler(attributes);
    }
  }

  // noinspection JSMethodCanBeStatic
  renderEditToolbar() {
    return null;
  }

  // noinspection JSMethodCanBeStatic
  renderEditInspector() {
    return null;
  }

  static __(...args) {
    return __(...args);
  }

  static config() {
    return {};
  }

  static getSetup(name) {}

  static get _supports() {
    return {};
  }

  static get _title() {
    if (typeof this.title != 'undefined') {
      return this.__(this.title);
    } else {
      return this.__(this.name);
    }
  }

  static get _category() {
    if (typeof this.category != 'undefined') {
      return this.category;
    } else {
      return 'smartfire';
    }
  }

  static get _icon() {
    if (typeof this.icon != 'undefined') {
      return this.icon;
    } else {
      return createElement(smartfireIcon, { color: 'unset' });
    }
  }

  static get _description() {
    if (typeof this.description != 'undefined') {
      return this.__(this.description);
    } else {
      return undefined;
    }
  }

  static get _keywords() {
    if (typeof this.keywords != 'undefined' && Array.isArray(this.keywords)) {
      return this.keywords.map(this.__.bind(this));
    } else {
      return [];
    }
  }

  static get _styles() {
    if (typeof this.styles != 'undefined' && Array.isArray(this.styles)) {
      return this.styles.map(this.__.bind(this));
    } else {
      return [];
    }
  }

  static get _attributes() {
    if (typeof this.attributes != 'undefined') {
      return this.attributes;
    } else {
      return {};
    }
  }

  static get _propTypes() {
    if (typeof this.propTypes != 'undefined') {
      return this.propTypes;
    } else {
      return {};
    }
  }
}
