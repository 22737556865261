import React, {useContext, useEffect, useMemo, useState} from 'react';
import {isEmpty} from 'lodash';

import {BlockViewer, RawViewer} from './BlockViewer';
// @ts-ignore
import {Parser as HtmlToReactParser} from 'html-to-react';
import {Column} from './library/Column';
import {Columns} from './library/Columns';
import type {BlockInstance} from '@wordpress/blocks';
import {Group} from './library/Group';
// @ts-ignore
import {parse} from '@wordpress/block-serialization-default-parser';
// @ts-ignore
import {v4 as uuidv4} from 'uuid';

if (customElements) {
    customElements.define('wp-block', class extends HTMLElement {

    });
}

const htmlToReactParser = new HtmlToReactParser();

class ErrorBoundary extends React.Component<{}, { hasError: boolean, error?: Error }> {
    state: { hasError: boolean, error?: Error } = {
        hasError: false,
        error: undefined,
    };

    static getDerivedStateFromError(error: Error) {
        return {hasError: true, error};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // call error logging or reporting service
        console.error(error, errorInfo);
        this.setState(ErrorBoundary.getDerivedStateFromError(error));
    }

    render() {
        if (this.state.hasError) {
            return <></>;
        }
        return this.props.children;
    }
}

interface BlockRender {
    clientId: string;
    attributes: { [key: string]: any };
    innerBlocks: BlockRender[];
    isValid: boolean;
    name: string;
    originalContent: string;
}

export const BlockRenderContext = React.createContext<BlockRender | null>(null);

function getAllSmartfireBlockAttributes(
    Block:any,
    attributes = {}
) {
    for (const [key, value] of Object.entries(Block._smartfire?.smartProps)) {
        if(!(key in attributes)){
            // @ts-ignore
            attributes[key] = value.default;
        }
    }
    return attributes;
}

export const RenderGenericSimpleBlock = ({block}: { block: BlockInstance }) => {
    return useMemo(() => htmlToReactParser.parse((block as any).innerHTML ?? ""), [block]);
};

function getRenderingHandler(block: BlockRender) {
    let name = block.name;
    switch (name) {
        case 'core/column':
            return Column;
        case 'core/columns':
            return Columns;
        case 'core/group':
            return Group;
        default:
            if (block.innerBlocks && block.innerBlocks.length > 0) {
                return () => <div>Error: block {name} is not supported for frontend rendering</div>;
            }
            return () => <RenderGenericSimpleBlock block={block}/>;
    }
}

export function RenderRawBlock({block}: { block: BlockRender }) {
    const Renderer: any = useMemo(() => getRenderingHandler(block), [block]);
    return <RawViewer __experimentalChildrenBlockRenderer={RenderBlockChildren}>
        <Renderer/>
    </RawViewer>;
}

export function RenderBlockChildren() {
    const parentBlock = useContext(BlockRenderContext);
    if (parentBlock === null || !parentBlock.innerBlocks) {
        return <></>;
    } else {
        return <>
            {parentBlock.innerBlocks?.map((block: BlockRender) => {
                return <RenderBlock block={block} key={block.clientId}/>;
            })}
        </>;
    }
}

export const RenderBlock: React.FC<{ block: BlockRender }> = ({block}) => {
    let renderingControl = useContext(RenderingControlContext);

    block.name = block.name ?? (block as any).blockName ?? "core/freeform";

    const [Component, setComponent] = useState<any>({value: undefined});
    const [loaded, setLoaded] = useState<boolean>(false);
    useEffect(() => {
        let componentLoader = window['smartfire'].components[block.name.split('/')[1]];
        if (componentLoader) {
            (async () => {
                const C = await componentLoader();
                block.attributes = getAllSmartfireBlockAttributes(C, block.attributes);
                setComponent({
                    value: BlockViewer({
                        block: C,
                        __experimentalChildrenBlockRenderer: RenderBlockChildren
                    })
                });
                setLoaded(true);
            })();
        } else {
            setComponent({value: () => <RenderRawBlock block={block}/>});
            setLoaded(true);
        }
    }, [block]);
    const blockName = useMemo(() => {
        const rawBlockName = block.name;
        return rawBlockName.startsWith('core/')
            ? rawBlockName.slice(5)
            : rawBlockName;
    }, [block]);
    const blockAttributes = useMemo(() => {
        const attributes = block.attributes;
        return !isEmpty(attributes)
            ? serializeAttributes(attributes) + ' '
            : '';
    }, [block]);
    if (!loaded) return null;
    const props = block.attributes;
    return <BlockRenderContext.Provider value={block}>
        <ErrorBoundary>
            {
                renderingControl.enableCustomWrapper ?
                    <>
                        { /* @ts-ignore */}
                        <wp-block name={blockName} attributes={blockAttributes}>
                            <Component.value {...props}/>
                            { /* @ts-ignore */}
                        </wp-block>
                    </> :
                    <>
                        <Component.value {...props}/>
                    </>
            }
        </ErrorBoundary>
    </BlockRenderContext.Provider>;
};


/**
 * Given an attributes object, returns a string in the serialized attributes
 * format prepared for post content.
 *
 * @param {Object} attributes Attributes object.
 *
 * @return {string} Serialized attributes.
 */
export function serializeAttributes(attributes: any) {
    return (
        JSON.stringify(attributes)
            // Don't break HTML comments.
            .replace(/--/g, '\\u002d\\u002d')

            // Don't break non-standard-compliant tools.
            .replace(/</g, '\\u003c')
            .replace(/>/g, '\\u003e')
            .replace(/&/g, '\\u0026')

            // Bypass server stripslashes behavior which would unescape stringify's
            // escaping of quotation mark.
            //
            // See: https://developer.wordpress.org/reference/functions/wp_kses_stripslashes/
            .replace(/\\"/g, '\\u0022')
    );
}

export type RenderingControl = {
    enableCustomWrapper: boolean
};

export const RenderingControlContext = React.createContext<RenderingControl>({
    enableCustomWrapper: false,
});

export function RenderBlocks({
                                 blocks,
                                 enableCustomWrapper = false
                             }: { blocks: BlockRender[], enableCustomWrapper?: boolean }) {
    const context = useMemo<RenderingControl>(() => ({enableCustomWrapper}), [enableCustomWrapper]);
    return <RenderingControlContext.Provider value={context}>
        {blocks.map((b, i) => <RenderBlock key={b.clientId || i} block={b}/>)}
    </RenderingControlContext.Provider>;
}

export function RenderPage({content}: { content: string }) {

    function handleBlocks(blocks: readonly any[]) {
        let newBlock = [...blocks];
        for (let i = 0; i < newBlock.length; i++) {
            let block = newBlock[i];
            if (!block.clientId) {
                block.clientId = uuidv4();

            }
            if (!block.name) {
                block.name = block.blockName ?? "core/freeform";
                delete block.blockName;
            }
            if (!block.attributes) {
                block.attributes = block.attrs;
                delete block.attrs;
            }
            if (block.innerBlocks) {
                block.innerBlocks = handleBlocks(block.innerBlocks);
            }
        }
        return newBlock;
    }


    const parsed = useMemo<BlockRender[]>(() => {
        if (content) {
            return handleBlocks(parse(content));
        }
        return [];
    }, [content]);
    return <ErrorBoundary>
        <RenderBlocks blocks={parsed}/>
    </ErrorBoundary>;
}

export default RenderPage;
