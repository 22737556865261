import * as PropType from 'prop-types';

function chaining(element, affectation) {
  return Object.assign(element, affectation);
}

export default class SmartProps {
  type = 'any';
  required = false;
  default = undefined;
  sidebar = false;
  kind = 'generic';
  imageSetup = { width: 300, height: 200 };

  constructor(type) {
    (t => {
      if (!PropType.hasOwnProperty(t)) {
        throw `${t} is not a valid property for SmartProps`;
      }
    })(type);
    this.type = type;
  }

  withForm() {
    this.sidebar = true;
    return this;
  }

  withDefault(value) {
    this.default = value;
    return this;
  }

  withImageExampleSize(width, height) {
    this.imageSetup = { width, height };
    return this;
  }

  get isRequired() {
    this.required = true;
    return this;
  }

  get propType() {
    return PropType[this.type];
  }

  static get string() {
    return new SmartProps('string');
  }
  static get number() {
    return new SmartProps('number');
  }
  static get array() {
    return new SmartProps('array');
  }
  static get bool() {
    return new SmartProps('bool');
  }
  static get object() {
    return new SmartProps('object');
  }
  static get any() {
    return new SmartProps('any');
  }
  static get image() {
    const prop = new SmartProps('object');
    prop.kind = 'image';
    return prop;
  }
  static get picto() {
    const prop = new SmartProps('object');
    prop.kind = 'picto';
    return prop;
  }
}
