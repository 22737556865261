import React, {createElement} from 'react';
import ReactDOM from 'react-dom';
import 'currentscript';
import { BlockViewer } from './BlockViewer'; // Polyfill for document.currentScript

let counter = 0;

export default function render(
  component,
  { attributes, innerBlocks, ...props },
  ...children
) {
  if (typeof component === 'string') {
    const name = component;
    let maybeOurComponent = window.smartfire.components[name];
    if (maybeOurComponent == null) {
      console.error(
        'Smartfire Component ' + name + ' is not defined on this website'
      );
      maybeOurComponent = () =>
        new Promise((resolve, reject) => {
          import('./components/fallback-block').then(
            ({ default: Fallback }) => resolve(() => <Fallback name={name} />)
          );
        });
    }
    component = maybeOurComponent();
  }
  Object.assign(props, attributes);
  if (typeof innerBlocks !== 'undefined' && Array.isArray(innerBlocks)) {
    props.innerBlocks = innerBlocks;
    for (let i = 0; i < innerBlocks.length; i++) {
      children[children.length] = createElement('div', {
        dangerouslySetInnerHTML: { __html: innerBlocks[i] },
      });
    }
  }
  const element = document.createElement('div');
  const id = btoa('element-' + counter++ + '-' + Math.random());
  element.id = id;
  const scriptCaller = document.currentScript;
  return Promise.resolve(component)
    .then(async component => {
      return new Promise((resolve, reject) => {
        try {
          scriptCaller.parentNode.appendChild(element);
          ReactDOM.render(
            createElement(
              BlockViewer({ block: component }),
              props,
              ...children
            ),
            element,
            () => {
              resolve();
            }
          );
        } catch (e) {
          try {
            document.write("<div id='" + id + "'/>");
            ReactDOM.render(
              createElement(
                BlockViewer({ block: component }),
                props,
                ...children
              ),
              document.getElementById(id),
              () => {
                resolve();
              }
            );
          } catch (e2) {
            reject({ cause: e, ...e2 });
          }
        }
      });
    })
    .then(element => element)
    .catch(e => console.error('Unable to load a Smartfire component', e));
}
