import { useSmartProp, useEditor } from '../..';
import {
  MediaPlaceholder,
  MediaUploadCheck,
  MediaUpload,
} from '@wordpress/block-editor';
import React from 'react';
import * as style from './style.module.scss';
import { Button, ButtonGroup, IconButton } from '@wordpress/components';

interface EditableImgProps {
  smartProp: string,
  className?: string,
  promptChangeText?: string,
  promptPosition?: string
}

export default function EditableImg({
  smartProp,
  className = '',
  promptChangeText = "Changer l'image",
  promptPosition = 'absolute',
  ...props
}: EditableImgProps) {
  const [img, update] = useSmartProp(smartProp);
  const { isEditing } = useEditor();
  if (isEditing) {
    return (
      <div
        className={
          promptPosition === 'relative'
            ? style.editableImgRelative
            : style.editableImg
        }
      >
        {img && img.url ? (
          <>
            <div className={style.buttons}>
              <MediaUploadCheck>
                <MediaUpload
                  onSelect={update}
                  allowedTypes={['image']}
                  value={img ? img.id : null}
                  render={({ open }: {open: () => void}) => (
                    <ButtonGroup>
                      <Button isDefault onClick={open}>
                        {promptChangeText}
                      </Button>
                      <IconButton
                        isDefault
                        isDestructive
                        icon="trash"
                        onClick={() => update(null)}
                      />
                    </ButtonGroup>
                  )}
                />
              </MediaUploadCheck>
            </div>
            <img className={className} src={img.url} alt={img.alt} {...props} />
          </>
        ) : (
          <MediaUploadCheck>
            <MediaPlaceholder
              value={img && img.url ? img : {}}
              onSelect={update}
            />
          </MediaUploadCheck>
        )}
      </div>
    );
  } else {
    if (img && img.url) {
      return (
        <img
          className={style.image + ' ' + className}
          src={img.url}
          alt={img.alt}
          {...props}
        />
      );
    } else {
      return null;
    }
  }
}
