export const toKebabCase = function(text) {
  const upperChars = text.match(/([A-Z])/g);
  if (!upperChars) {
    return text;
  }

  let str = text.toString();
  for (var i = 0, n = upperChars.length; i < n; i++) {
    str = str.replace(
      new RegExp(upperChars[i]),
      '-' + upperChars[i].toLowerCase()
    );
  }

  if (str.slice(0, 1) === '-') {
    str = str.slice(1);
  }

  return str;
};

export function toPascalCase(string) {
  return toCamelCase(string).replace(new RegExp(/\w/), s => s.toUpperCase());
}

export function toCamelCase(string) {
  return `${string}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w+)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\s/, 'g'), '');
}

export function serializeAttributes(attributes) {
  return (
    JSON.stringify(attributes)
      // Don't break HTML comments.
      .replace(/--/g, '\\u002d\\u002d')

      // Don't break non-standard-compliant tools.
      .replace(/</g, '\\u003c')
      .replace(/>/g, '\\u003e')
      .replace(/&/g, '\\u0026')

      // Bypass server stripslashes behavior which would unescape stringify's
      // escaping of quotation mark.
      //
      // See: https://developer.wordpress.org/reference/functions/wp_kses_stripslashes/
      .replace(/\\"/g, '\\u0022')
  );
}
