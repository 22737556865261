import { createBlock } from '@wordpress/blocks';
import { save, edit, backward_compatibility } from './BlockEditor';
import { smartfireIcon } from './icon';
import {createElement} from "react";

/**
 * Configuration to pass to Gutenberg editor.
 * @see https://developer.wordpress.org/block-editor/developers/block-api/block-registration/
 */
const Configuration = {
  /**
   * Human name of the component to display in the list
   * @type {null|string}
   */
  title: null,

  /**
   * Icon to illustrate your component.
   * See Dashicons for default provided icons.
   * @type {string|React.Component}
   */
  icon: createElement(smartfireIcon, { color: 'unset' }),

  /**
   * This is a short description for your block, which can be translated.
   * @type {null|string}
   */
  description: null,

  /**
   * Blocks are grouped into categories to help users browse and discover them.
   * Defaults ones: common | formatting | layout | widgets | embed | smartfire
   * @type {string}
   */
  category: 'smartfire',

  /**
   * Sometimes a block could have aliases that help users discover it while searching.
   * This is translatable.
   * @type {string[]}
   */
  keywords: [],

  /**
   * Example provides structured example data for the block. This data is used to construct
   * a preview for the block to be shown in the Inspector Help Panel when the user mouses
   * over the block.
   * @type {null|{}|{attributes: Map<string, *>}}
   */
  example: {},

  /**
   * Setup transform rules from this block to other blocks.
   * See: https://developer.wordpress.org/block-editor/developers/block-api/block-registration/#transforms-optional
   * @type {{from: [*], to: [*]}}
   */
  transforms: {
    from: [],
    to: [],
  },

  /**
   * Blocks are able to be inserted into blocks that use InnerBlocks as nested content.
   * Sometimes it is useful to restrict a block so that it is only available as a nested block.
   * For example, you might want to allow an ‘Add to Cart’ block to only be available within a ‘Product’ block.
   *
   * Assigning it as empty array will disable block from backend editor.
   *
   * @type {string[]|null}
   *
   */
  parent: null,

  /**
   * Settings for the wordpress environment.
   * See: https://developer.wordpress.org/block-editor/developers/block-api/block-registration/#supports-optional
   */
  supports: {
    alignWide: true,
    anchor: true,
    align: false,
    customClassName: false,
    className: true,
    html: false,
    inserter: true,
    multiple: true,
    reusable: false,
  },

  /**
   * Attributes provide the structured data needs of a block.
   *
   * They can exist in different forms when they are serialized,
   * but they are declared together under a common interface.
   *
   * Prefer to use the smartProps support instead of this one.
   *
   * @see https://developer.wordpress.org/block-editor/developers/block-api/block-attributes/
   */
  attributes: {},
};

/**
 *
 * @param smartProps {Map<string, smartProps>}
 * @param configuration {Configuration}
 * @returns {*}
 */
function mapSmartPropsToWordpressProps(smartProps, configuration) {
  const attributes = { ...configuration.attributes };
  Object.keys(Object.assign({}, smartProps)).map(property => {
    const prop = smartProps[property];
    attributes[property] = {
      type: prop.type,
      default: prop.default,
    };
  });
  return attributes;
}

function mapDefaultExample(smartProps, exampleConfig = {}) {
  if (exampleConfig === 'disable') {
    return null;
  }
  const { innerBlocks, ...definedExamples } = exampleConfig;
  const example = { attributes: {} };
  if (innerBlocks) {
    example.innerBlocks = innerBlocks;
  }
  Object.keys(smartProps).forEach(propName => {
    const prop = smartProps[propName];
    switch (prop.kind) {
      case 'generic':
        if (definedExamples[propName])
          example.attributes[propName] = definedExamples[propName];
        else example.attributes[propName] = prop.default;
        break;
      case 'image':
        example.attributes[propName] = {
          url: `https://picsum.photos/${
            prop.imageSetup ? prop.imageSetup.width : 200
          }/${prop.imageSetup ? prop.imageSetup.height : 300}`,
        };
        break;
      case 'picto':
        const randomInt = Math.floor(Math.random() * Math.floor(300));
        example.attributes[propName] = {
          url:
            'https://www.flaticon.com/svg/static/icons/svg/149/149' +
            randomInt +
            '.svg',
        };
    }
  });
  return example;
}

/**
 * Transform a React component to a block usable by Smartfire.
 *
 * Smartfire handles any React component, but some metadata can be
 * required to use in conjunction with tools like Gutenberg Editor.
 *
 * This methods handles your specified setup configuration, if a
 * required setting is not provided a default one will be provided.
 *
 * @param component {React.Component|*} The React component to setup
 * @param smartProps {Map<string, smartProps>} Properties setup for the component, if you use editor update,
 *                                             you have to register your properties
 * @param setup {Configuration} Configuration to pass on editor
 * @returns {React.Component}
 */
export default function defineBlock({
  Block: component,
  smartProps = {},
  setup = {},
}) {
  component._smartfire = {
    smartProps,
    configureGutenberg(name) {
      const configuration = Object.assign({}, Configuration, setup || {});
      const attributes = mapSmartPropsToWordpressProps(
        smartProps || {},
        configuration
      );
      const example = mapDefaultExample(smartProps, configuration.example);
      if (!configuration.title) {
        configuration.title = component.name;
      }
      return {
        edit: edit(component, name),
        save: save(component, name),
        ...configuration,
        example,
        attributes,
        supports: {
          ...configuration.supports,
        },
        deprecated: [
          {
            attributes,
            save: save(component, name),
          },
          ...backward_compatibility.map(b => ({
            attributes,
            save: b.save(component, name),
          })),
          ...(configuration.deprecated || []).map(depreciation => {
            if (depreciation.save) {
              return depreciation;
            } else {
              return { ...depreciation, save: save(component, name) };
            }
          }),
        ],
        transforms: {
          from: [
            {
              type: 'raw',
              priority: 4,
              isMatch: node => {
                return (
                  createBlock &&
                  node.dataset &&
                  node.dataset.smartfireType === name
                );
              },
              transform: node => {
                const script = node.children[node.children.length - 1];
                if (script.nodeName !== 'SCRIPT') {
                  return createBlock(name);
                }
                let wpProps = JSON.parse(
                  script.innerHTML.split(/[(),]/g)[5].trim()
                );
                if (wpProps) {
                  let { attributes } = wpProps;
                  return createBlock(name, attributes, []);
                } else {
                  return createBlock(name);
                }
              },
            },
            ...(configuration.transforms.from || []),
          ],
          to: configuration.transforms.to || [],
        },
      };
    },
  };
  if (!component.propTypes) {
    // PropTypes have not be declared, we infer them from smartProps
    component.propTypes = {};
    Object.keys(smartProps).map(
      k => (component.propTypes[k] = smartProps[k].propType)
    );
  }
  return component;
}
