import React, { useEffect } from "react";
import {defineBlock, smartProps, useInspectorControl, useSmartProp, EditableText} from "../..";
import { PanelBody, PanelRow, ToggleControl, TextControl, Button, SelectControl  } from '@wordpress/components';
import DeleteIcon from '@material-ui/icons/Delete';

function Inspector(){
    const keysForms = window.keysFields;
    const currentFormId = window.currentIdForm;
    const keys = keysForms.filter(form => form.value === currentFormId)[0];
    const keysSelect = keys.select;

    const [required, setRequired] = useSmartProp("required");
    const [defaultLabel, setDefaultLabel] = useSmartProp('defaultLabel');
    const [options, setOptions] = useSmartProp('options');
    const [name, setName] = useSmartProp('name');

    useEffect(() => {
        if (!name) setName(keysSelect[0].value);
    });

    const optionsList = () => {
        if (name) {
            const key = keysSelect.find(k => k.value === name);
            if (key) return key.options
        }
        return false;
    }

    function addOption(){
        const copy=[...options]
        const key = keysSelect.find(k => k.value === name);
        if (key && key.options[0] && key.options[0].value) {
            copy.push({
                label: '',
                value: key.options[0].value
            })
        }
        setOptions(copy)
    }

    function deleteOption(i){
        let copy=[...options]
        copy.splice(i,1);
        setOptions(copy);
    }

    function updateOption(i, value, prop){
        let copy=[...options];
        copy[i][prop]=value;
        setOptions(copy);
    }

    return <>
        <PanelBody title="Options" initialOpen={true}>
            <PanelRow>
                <ToggleControl
                    label="Requis"
                    checked={required}
                    onChange={setRequired}
                />
            </PanelRow>
            <PanelRow>
                <SelectControl
                    label="Clé"
                    value={name}
                    options={keysSelect}
                    onChange={setName}
                />
            </PanelRow>
            <PanelRow>
                <TextControl
                    label="Option par défaut"
                    value={defaultLabel}
                    onChange={setDefaultLabel}
                />
            </PanelRow>
            <PanelRow>
                <Button
                    isPrimary={true}
                    onClick={()=> addOption()}>
                    Ajouter une option
                </Button>
            </PanelRow>
        </PanelBody>
        {options && options.length>0 && options.map((o, i) => (
            <PanelBody title={"Option "+(i+1)} initialOpen={false}>
                <PanelRow>
                    <TextControl
                        label="Label"
                        value={o.label}
                        onChange={(value) => updateOption(i, value, "label")}
                    />
                </PanelRow>
                <PanelRow>
                    <SelectControl
                        label="Clé"
                        value={o.value}
                        options={optionsList()}
                        onChange={(value) => updateOption(i, value, "value")}
                    />
                </PanelRow>
                <PanelRow>
                    <Button
                        isPrimary={true}
                        onClick={() => deleteOption(i)}
                    >
                        <DeleteIcon/>Supprimer
                    </Button>
                </PanelRow>
            </PanelBody>
        ))}
    </>

}

function SmartFormSelect ({required, options, name, defaultLabel}) {
    useInspectorControl(Inspector);
    
    return <div>
        <EditableText tagName="label" placeholder="Label" smartProp="label" /> {required ? <span> *</span> : ""}
        <select required={required} name={name}>
            <option value={null}>{defaultLabel}</option>
            {options && options.map((o, i) => (
                <option key={i} value={o.value}>{o.label}</option>
            )) }
        </select>
    </div>
}

export default defineBlock({
    Block: SmartFormSelect,
    smartProps: {
        label: smartProps.string.withDefault('label'),
        required: smartProps.bool.withDefault(false),
        options: smartProps.any.withDefault([]),
        defaultLabel: smartProps.string.withDefault('Select'),
        name: smartProps.string.withDefault()
    },
    setup: {
        title: "Select",
    }
})