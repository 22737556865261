import React from "react";
import { useSmartProp, useToolbar } from '../..';
import {MediaUpload, MediaUploadCheck} from '@wordpress/block-editor';
import { ToolbarButton} from '@wordpress/components';
import { Parallax } from 'react-parallax';

const ALLOWED_MEDIA_TYPES = ['image'];

export default function SmartParallax ({backgroundImage, children, strength, className, minHeight, media}) {

    const [img, setImg] = useSmartProp(backgroundImage);

    useToolbar(() => {
        return  <MediaUploadCheck>
            <MediaUpload
                onSelect={(image) => setImg(image)}
                allowedTypes={ALLOWED_MEDIA_TYPES}
                value={img ? img.id : null}
                render={({ open }) => (
                    <ToolbarButton onClick={open} icon='camera' label="Modifier l'image" />
                )}
            />
        </MediaUploadCheck>
    }, [backgroundImage, setImg]);

    return <>
            <Parallax
                bgImage={media ? media.url : (img && img.url)}
                bgImageAlt={media ? media.alt : (img && img.alt)}
                strength={strength ? strength : 300}
                blur={{min: 0, max: 0}}
            >
                <div className={className} style={{ minHeight: minHeight ? `${minHeight}px` : `${media ? media.height : (img && img.height)}px`}}>
                    {children}
                </div>
            </Parallax>
    </>
}
