import React from 'react';

export const smartfireYellow = '#FFD92B';

export const smartfireIcon = function({ color = smartfireYellow }) {
  return (
    <svg viewBox="0 0 89 178" style={{ enableBackground: 'new 0 0 89 178' }}>
      <g>
        <path
          style={{ fill: color ? color : smartfireYellow }}
          d="M24,175.7c-0.7,0-1.5-0.2-2.1-0.5c-1.7-0.8-2.9-2.6-2.9-4.5v-22c0-7.4,3.6-14.5,9.9-19.6l18.6-15.1
c3.4-2.7,5.2-6.3,5.2-10c0-3.7-1.8-7.3-5.2-10l-10.8-8.7c-5.7-4.7-8.9-11-8.9-17.8s3.2-13.1,8.9-17.8l16.5-13.4
c5.5-4.4,7.3-10.8,4.8-16.5l-1.8-4.3L25.8,40.1C11.6,51.6,7.4,68.7,15,83.6c2.5,4.9,6.1,9.2,10.8,13c2.1,1.7,2.5,4.9,0.7,7
c-1.7,2.1-4.9,2.5-7,0.7C13.8,99.7,9.3,94.2,6.1,88.1C-3.8,68.9,1.5,47,19.5,32.3L55,3.5c1.2-1,2.8-1.3,4.3-1
c1.5,0.4,2.8,1.4,3.4,2.9l4.5,10.3c4.3,9.9,1.3,21-7.7,28.3L43,57.4c-3.4,2.7-5.2,6.3-5.2,10c0,3.7,1.8,7.3,5.2,10l10.8,8.7
c5.7,4.7,8.9,11,8.9,17.8s-3.2,13.1-8.9,17.8l-18.6,15.1c-4,3.2-6.2,7.4-6.2,11.8v11.5l34.4-27.9c14.4-11.7,18.4-28.9,10.2-43.9
c-2.4-4.5-5.9-8.5-10.2-12.1c-2.1-1.7-2.5-4.9-0.7-7c1.7-2.1,4.9-2.5,7-0.7c5.4,4.4,9.6,9.4,12.7,15c10.4,19.1,5.3,41.8-12.7,56.4
l-42.5,34.6C26.2,175.3,25.1,175.7,24,175.7z"
        />
      </g>
    </svg>
  );
};
