import React, { Component, createElement, createRef, useContext } from 'react';
import { BlockContext } from './BlockViewer';

/**
 * Support children DOM of the current Smartfire block.
 * It expects to go up the DOM and find an element containing both a script element and a div with a class called
 * children. It takes the content of this DIV and append it to its DOM node.
 *
 * ```
 * <div class="smartfireBlock">
 *     <div class="children" style="display: none">
 *         <div>My Super element to be considered as children</div>
 *     </div>
 *     <script>ReactDOM.render(MySuperReactComponent, document.getElementById("renderDiv");</script>
 *     <div id="renderDiv"></div>
 * </div>
 * ```
 *
 */
export class GenericChildrenBlock extends Component {
  constructor(props) {
    super(props);
    this.refContainer = createRef();
  }

  getCurrentContainerNode() {
    let parentNode = ReactDOM.findDOMNode(this).parentNode;
    while (parentNode != null && parentNode.querySelector('script') == null) {
      parentNode = parentNode.parentNode;
    }
    let containerNode =
      parentNode !== null
        ? parentNode.querySelector('div.smartfireBlockChildren')
        : null;
    return containerNode !== null && containerNode.parentNode === parentNode
      ? containerNode
      : null;
  }

  static moveChildren(oldParent, newParent) {
    while (oldParent.childNodes.length > 0) {
      newParent.appendChild(oldParent.childNodes[0]);
    }
  }

  componentDidMount() {
    let node = this.getCurrentContainerNode();
    if (node) {
      GenericChildrenBlock.moveChildren(node, this.refContainer.current);
    } else {
      console.error(
        'Nothing to render inside the ChildrenBlock',
        this.refContainer.current,
        this
      );
    }
  }

  componentWillUnmount() {
    let node = this.getCurrentContainerNode();
    if (node) {
      GenericChildrenBlock.moveChildren(this.refContainer.current, node);
    }
  }

  render() {
    return createElement('div', { ref: this.refContainer });
  }
}


export default function ChildrenBlock() {
  let blockContext = useContext(BlockContext.Context);
  if (!blockContext || !blockContext.__experimentalChildrenBlockRenderer) {
    return React.createElement(GenericChildrenBlock);
  } else {
    return React.createElement(blockContext.__experimentalChildrenBlockRenderer);
  }
}