import React from "react";
import { useEditor, EditableText, ChildrenBlock } from '../..';
import { InnerBlocks } from '@wordpress/block-editor';

const ALLOWED_BLOCKS = ['smartfire/checkbox', 'smartfire/select', 'smartfire/input', 'smartfire/textarea'];

export default function SmartForm({
    className,
    onSubmit,
    smartProp,
    idForm,
    children
}) {
    const { isEditing } = useEditor();

    const Wrapper = isEditing ? 'div' : 'form';
    window.currentIdForm = idForm

    return <Wrapper className={className} method={isEditing ? null : "POST"} onSubmit={onSubmit}>
        {isEditing ?
            <InnerBlocks
                allowedBlocks={ALLOWED_BLOCKS}
                templateLock={false}
            /> :
            <ChildrenBlock />
        }
        {children}
        <div>
            <EditableText tagName="button" smartProp={smartProp} onClick={null} type="submit" />
        </div>
    </Wrapper>

}