import React from 'react';
import * as Internals from './Internals';
import Smartfire from './Smartfire';
import {BlockViewer, RawViewer} from './BlockViewer';
import * as HtmlRenderUtils from './HtmlRender';

if (window && !window.smartfire) {
  Internals.wordpress();
  Internals.publishSmartfireInScope(Smartfire);
}

export default Smartfire;
export const registerBlock = Smartfire.registerBlock;
/**
 * Create a Smartfire component as a class.
 * @deprecated Prefer to use a Hook instead
 * @type {SmartfireComponent}
 */
export {default as SmartfireComponent} from "./SmartfireComponent";
export const ChildrenBlock = Smartfire.ChildrenBlock;
export const smartProps = Smartfire.smartProps;
export const useEditor = Smartfire.useEditor;
export const useInspectorControl = Smartfire.useInspectorControl;
export const useToolbar = Smartfire.useToolbar;
export const defineBlock = Smartfire.defineBlock;
export const useSmartProp = Smartfire.useSmartProp;
export const __experimentalRawViewer = RawViewer;
export const __experimentalBlockViewer = BlockViewer;
export const __experimentalHtmlRender = HtmlRenderUtils;
export const RenderContent = HtmlRenderUtils.RenderPage;
export {registerBlocks} from "./BlockSupport";
export * from './components';
export * from './Block';
