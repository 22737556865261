import React from 'react';
import { useSmartProp, useEditor } from '../..';

import { RichText } from '@wordpress/block-editor';

export default function EditableText({
  smartProp,
  className = undefined,
  style = undefined,
  tagName = 'div',
  tagProps = {},
  render = function (value, props = {}, TagName) {
    return <TagName dangerouslySetInnerHTML={{ __html: value }} {...props} />;
  },
  ...props
}) {
  const [value, updateValue] = useSmartProp(smartProp);
  const { isEditing } = useEditor();
  if (isEditing) {
    return (
      <div className={className}>
        <RichText
          tagName={tagName}
          placeholder={'...'}
          formattingControls={[]}
          value={value}
          className={'editableInput'}
          onChange={updateValue}
          {...props}
        />
      </div>
    );
  } else {
    return render(
      value,
      {
        className,
        style,
        ...tagProps
      },
      tagName
    );
  }
}
