import ChildrenBlock from '../ChildrenBlock';
import React from 'react';
import clsx from 'clsx';


export const Columns = ({ verticalAlignment }: {verticalAlignment?: string}) => {
    const className = clsx( {
        [`wp-block-columns`]: true,
        [ `are-vertically-aligned-${ verticalAlignment }` ]: verticalAlignment,
    } );

    return <div className={className}>
        <ChildrenBlock/>
    </div>
}