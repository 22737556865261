import defineBlock from './defineBlock';
import render from './render';
import { smartfireIcon, smartfireYellow } from './icon';
import smartProps from './smartProps';
import ChildrenBlock from './ChildrenBlock';
import { hooks as editorHooks } from './BlockEditor';
import registerBlock from './registerBlock';
import RenderPage from './HtmlRender';

export type SmartfireLib = {
  RenderPage: typeof RenderPage,
  defineBlock: typeof defineBlock,
  block: typeof defineBlock,
  render: typeof render,
  registerBlock: typeof registerBlock,
  ChildrenBlock: typeof ChildrenBlock,
  smartProps: typeof smartProps,
  _brand: {
    icon: typeof smartfireIcon,
    colors: {[name:string]: string}
  },
  components: {[name: string]: () => any}
} & typeof editorHooks;

const Smartfire: SmartfireLib = {
  RenderPage,
  defineBlock,
  block: defineBlock,
  render,
  registerBlock,
  ChildrenBlock,
  //SmartfireComponent,
  smartProps,
  _brand: {
    icon: smartfireIcon,
    colors: {
      yellow: smartfireYellow,
    },
  },
  ...editorHooks,
  components: {}
};

export default Smartfire;
