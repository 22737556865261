import {
  useCallback,
  useContext,
  useDebugValue,
  useEffect,
  useMemo,
  useRef,
  useState,
    Fragment,
} from 'react';
import BlockEditorContext from './BlockEditorContext';
import {
  BlockControls,
  InnerBlocks,
  InspectorControls,
} from '@wordpress/editor';
import { serializeAttributes, toPascalCase } from './utils';
import { BlockContext } from './BlockViewer';

const Toolbar = () => {
  const editor = useContext(BlockEditorContext.Context);
  const toolbars = BlockEditorContext.useToolbarClient(editor);
  return (
    <BlockControls>
      {toolbars.map((v, i) => (
        <Fragment key={i}>{v}</Fragment>
      ))}
    </BlockControls>
  );
};
const Inspector = () => {
  const editor = useContext(BlockEditorContext.Context);
  const inspectors = BlockEditorContext.useInspectorControlClient(editor);
  return (
    <InspectorControls>
      {inspectors.map((v, i) => (
        <Fragment key={i}>{v}</Fragment>
      ))}
    </InspectorControls>
  );
};

function BlockEditor({ block: Block }) {
  return ({ attributes, setAttributes, className, isSelected, ...props }) => {
    const context = useMemo(
      () => new BlockEditorContext(setAttributes, Block),
      [Block, setAttributes]
    );
    useEffect(() => {
      context.updateAttributes(attributes);
    }, [attributes, context]);
    const Provider = BlockEditorContext.Context.Provider;
    return (
      <Provider value={context}>
        {/* Toolbar and Inspector must be rendered before the Block, otherwise, init render of the Block fails to
            have any toolbar nor Inspector*/}
        <Toolbar />
        <Inspector />
        <Block {...attributes} />
      </Provider>
    );
  };
}

export function ClassicBlockWrapper(Block) {
  const originalRender = Block.prototype.render;
  Block.prototype.render = function() {
    return (
      <>
        <InspectorControls>{this.renderEditInspector()}</InspectorControls>
        <BlockControls>{this.renderEditToolbar()}</BlockControls>
        {originalRender.apply(this)}
      </>
    );
  };
  let newBlock = ({ ...props }) => {
    const component = useRef(null);
    const {
      isEditing: editing,
      updateData: setAttributesHandler,
    } = hooks.useEditor();
    return (
      <Block
        {...props}
        editing={editing}
        setAttributesHandler={setAttributesHandler}
        ref={component}
      />
    );
  };
  newBlock.classSupport = true;
  newBlock._name = Block.name;
  return newBlock;
}

/**
 *
 * @param block
 * @returns {{icon: *, description: *, title: *, category: *}}
 */
ClassicBlockWrapper.extractSetup = block => ({
  title: block._title,
  icon: block._icon, // do not use the horrible default value
  category: block._category, // By pass default
  description: block._description, // By pass default
  ...block.config(),
});

export const outOfScope = data => {
  console.error(
    '[Smartfire Engine] You tried to set data inside a non editor environment',
    data
  );
};

export const hooks = {
  /**
   *
   * @returns {{isEditing: boolean, updateData: function}}
   */
  useEditor() {
    const context = useContext(BlockEditorContext.Context);
    const [editing, setEditing] = useState(() => {
      if (context) {
        return { isEditing: true, updateData: context.updater };
      } else {
        return { isEditing: false, updateData: outOfScope };
      }
    });
    useEffect(() => {
      if (context) {
        setEditing({ isEditing: true, updateData: context.updater });
      } else {
        setEditing({ isEditing: false, updateData: outOfScope });
      }
    }, [context, setEditing]);
    return editing;
  },

  useInspectorControl(rendering, deps = []) {
    const context = useContext(BlockEditorContext.Context);
    BlockEditorContext.useInspector(context, rendering, deps);
  },

  useToolbar(rendering, deps = []) {
    const context = useContext(BlockEditorContext.Context);
    BlockEditorContext.useToolbar(context, rendering, deps);
  },

  useSmartProp(name) {
    const frontContext = useContext(BlockContext.Context);
    if (frontContext) {
      // Do not use editor elements hook on frontend
      return [frontContext.attributes[name], v => outOfScope({ [name]: v })];
    }
    const context = useContext(BlockEditorContext.Context);
    const value = BlockEditorContext.useSmartProp(context, name);
    const { updateData } = hooks.useEditor();
    const setter = useCallback(
      newValue => {
        return updateData({ [name]: newValue });
      },
      [updateData, name]
    );
    return [value, setter];
  },
};

// If you change this function, please correct the depreciation element below.
export const save = function(block, name) {
  return function({ attributes }) {
    const serializedName = block.classSupport
      ? block._name
      : toPascalCase(name);
    return (
      <div data-smartfire-type={serializedName}>
        <div
          dangerouslySetInnerHTML={{
            __html: `<!-- This is a smartfire component rendered on runtime (see https://smartfire.pro). -->`,
          }}
        />
        <div className="smartfireBlockChildren" style={{ display: 'none' }}>
          <InnerBlocks.Content />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              '(function(){smartfire.render("' +
              serializedName +
              '", ' +
              serializeAttributes({ attributes }) +
              ')})()',
          }}
        />
      </div>
    );
  };
};

/**
 * Backward compatibility is to ensure a smooth experience when we upgrade the save function.
 * If you do so, please upgrade that function array accordingly.
 * <ul>
 *     <li>versions is the range of valid versions for this save method
 *     <li>save is the applied save method on this versions
 * </ul>
 * @type {{versions: string, save: function}[]}
 */
export const backward_compatibility = [
  {
    versions: '2.2.0 - 3.0.0',
    save: function(block, name) {
      return function({ attributes }) {
        const serializedName = block.classSupport
          ? block._name
          : toPascalCase(name);
        return (
          <div data-smartfire-type={serializedName}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<!-- This is a smartfire component rendered on runtime (see https://smartfire.pro). -->`,
              }}
            />
            <div className="smartfireBlockChildren" style={{ display: 'none' }}>
              <InnerBlocks.Content />
            </div>
            <script
              dangerouslySetInnerHTML={{
                __html:
                  '(function(){smartfire.render("' +
                  serializedName +
                  '", ' +
                  serializeAttributes({ attributes }) +
                  ')})()',
              }}
            />
          </div>
        );
      };
    },
  },
  {
    versions: '2.0.0 - 2.1.2',
    save: function(block, name) {
      return function({ attributes }) {
        const serializedName = block.classSupport
          ? block._name
          : toPascalCase(name);
        return (
          <div data-smartfire-type={serializedName}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<!-- Hello editor,
this code is designed to directly call Smartfire library, we advise you to not try
to edit it. It will be recovered from the initial state.-->`,
              }}
            />
            <div className="smartfireBlockChildren" style={{ display: 'none' }}>
              <InnerBlocks.Content />
            </div>
            <script
              dangerouslySetInnerHTML={{
                __html:
                  '(function(){smartfire.render("' +
                  serializedName +
                  '", ' +
                  JSON.stringify({ attributes }) +
                  ')})()',
              }}
            />
          </div>
        );
      };
    },
  },
];

export const edit = function(block, name) {
  const Editor = BlockEditor({ block });
  return function({ ...props }) {
    useDebugValue(name);
    return <Editor {...props} />;
  };
};
