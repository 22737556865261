import ChildrenBlock from '../ChildrenBlock';
import React from 'react';
import clsx from 'clsx';

export const Column = ({ verticalAlignment, width }:{verticalAlignment?: string, width?: number | string}) => {
    const wrapperClasses = clsx( {
        [`wp-block-column`]: true,
        [ `is-vertically-aligned-${ verticalAlignment }` ]: verticalAlignment,
    } );
    let style;

    if ( width ) {
        // Numbers are handled for backward compatibility as they can be still provided with templates.
        style = { flexBasis: Number.isFinite( width ) ? width + '%' : width };
    }
    return <div className={wrapperClasses} style={style}>
        <ChildrenBlock/>
    </div>
}