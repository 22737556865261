import React, { useEffect } from "react";
import {defineBlock, smartProps, useInspectorControl, useSmartProp, EditableText, useEditor} from "../..";
import { PanelBody, PanelRow, ToggleControl, SelectControl, TextControl  } from '@wordpress/components';

function Inspector(){
    const keysForms = window.keysFields;
    const currentFormId = window.currentIdForm;
    const keys = keysForms.filter(form => form.value === currentFormId)[0];
    const keysInput = keys.input;

    const [required, setRequired] = useSmartProp("required");
    const [typeField, setTypeField] = useSmartProp('typeField');
    const [name, setName] = useSmartProp('name');
    const [placeholder, setPlaceholder] = useSmartProp('placeholder');

    useEffect(() => {
        if (name === undefined || keysInput[typeField].filter(field => field.value === name).length === 0) {
            setName(keysInput[typeField][0].value);
        }
    }, [typeField]);

    const optionsType = [
        (keysInput.text && {label: 'Texte', value: 'text'}),
        (keysInput.url && {label: 'Url', value: 'url'}),
        (keysInput.email && {label: 'Email', value: 'email'}),
        (keysInput.number && {label: 'Nombre', value: 'number'}),
        (keysInput.tel && {label: 'Téléphone', value: 'tel'}),
    ].filter(item => item);

    return <PanelBody title="Options" initialOpen={true}>
        <PanelRow>
            <SelectControl
                value={typeField}
                label="Type du champ"
                options={optionsType}
                onChange={setTypeField}
            />
        </PanelRow>
        <PanelRow>
            <ToggleControl
                label="Requis"
                checked={required}
                onChange={setRequired}
            />
        </PanelRow>
        <PanelRow>
            <TextControl
                label="Placeholder"
                value={placeholder}
                onChange={setPlaceholder}
            />
        </PanelRow>
        <PanelRow>
            <SelectControl
                label="Clé"
                value={name}
                options={keysInput[typeField]}
                onChange={setName}
            />
        </PanelRow>
    </PanelBody>
}

function SmartFormInput ({label, required, name, placeholder, typeField}) {
    const { isEditing } = useEditor();
    useInspectorControl(Inspector);

    return <div>
        {isEditing ? 
            <EditableText tagName="label" placeholder="Label" smartProp="label" htmlFor={name} />:
            <label htmlFor={name} dangerouslySetInnerHTML={{__html: label}}></label>
        }
        <input placeholder={placeholder} name={name} required={required} type={typeField} id={name} />
    </div>
}

export default defineBlock({
    Block: SmartFormInput,
    smartProps: {
        label: smartProps.string.withDefault('label'),
        placeholder: smartProps.string.withDefault('placeholder'),
        required: smartProps.bool.withDefault(false),
        typeField: smartProps.string.withDefault('text'),
        name: smartProps.string.withDefault()
    },
    setup: {
        title: "Input",
    }
})