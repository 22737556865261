import {createContext, createElement, useEffect, useMemo, useState} from 'react';

export default class BlockEditorContext {
  static Context = createContext();

  block = null;
  toolbars = [];
  inspectors = [];
  attributes = {};

  stateSetters = {
    toolbars: [],
    inspectors: [],
    attributes: [],
  };

  updater = 'rogueValue';

  constructor(updater, block) {
    this.updater = updater;
    this.block = block;
  }

  updateAttributes(attributes) {
    this.attributes = attributes;
    this.stateSetters.attributes.forEach(set => set(attributes));
  }

  registerToolbar(component) {
    return this._refresh('toolbars', this.stateSetters.toolbars, component);
  }

  registerInspector(component) {
    return this._refresh('inspectors', this.stateSetters.inspectors, component);
  }

  _refresh(storeName, listeners, component) {
    if (!component) return;
    this[storeName].push(component);
    listeners.forEach(s => s([...this[storeName]]));
    return () => {
      this[storeName].splice(this[storeName].indexOf(component), 1);
      listeners.forEach(s => s([...this[storeName]]));
    };
  }

  static useToolbar(editor, render, deps) {
    let Provider = this.Context.Provider;
    const component = useMemo(
      () => (
        <Provider value={editor}>
          {createElement(render, { editor })}
        </Provider>
      ),
      [...deps, editor]
    );
    useEffect(() => {
      if (editor) {
        return editor.registerToolbar(component);
      }
    }, [...deps, editor]);
  }

  static useInspector(editor, render, deps) {
    let Provider = this.Context.Provider;
    const component = useMemo(
      () => (
        <Provider value={editor}>
          {createElement(render, { editor })}
        </Provider>
      ),
      [...deps, editor]
    );
    useEffect(() => {
      if (editor) {
        return editor.registerInspector(component);
      }
    }, [...deps, editor, component]);
  }

  /**
   *
   * @param editor
   * @returns {[]}
   */
  static useToolbarClient(editor) {
    const [toolbars, setToolbars] = useState(editor ? editor.toolbars : []);
    useEffect(() => {
      if (editor) {
        return editor._trackState('toolbars', setToolbars);
      }
    }, [editor, setToolbars]);
    return toolbars;
  }

  static useInspectorControlClient(editor) {
    const [inspectors, setInspectors] = useState(
      editor ? editor.inspectors : []
    );
    useEffect(() => {
      if (editor) {
        return editor._trackState('inspectors', setInspectors);
      }
    }, [editor, setInspectors]);
    return inspectors;
  }

  static useAttributes(editor) {
    const [attributes, setAttributes] = useState(
      editor ? editor.attributes : {}
    );
    useEffect(() => {
      if (editor) {
        return editor._trackState('attributes', setAttributes);
      }
    }, [editor, setAttributes]);
    return attributes;
  }

  static useSmartProp(editor, name) {
    const attributes = this.useAttributes(editor);
    return attributes[name];
  }

  _trackState(property, setter) {
    this.stateSetters[property].push(setter);
    return () => {
      delete this.stateSetters[property][
        this.stateSetters[property].indexOf(setter)
      ];
    };
  }
}
