import { useMemo, createContext } from 'react';


export class BlockContext {
  static Context = createContext(null);
  __experimentalChildrenBlockRenderer = null;
  attributes;
  Block;
  constructor(attributes, Block, __experimentalChildrenBlockRenderer = null) {
    this.attributes = attributes;
    this.Block = Block;
    this.__experimentalChildrenBlockRenderer = __experimentalChildrenBlockRenderer;
  }
}

export function BlockViewer({ block: Block, __experimentalChildrenBlockRenderer }) {
  return function Viewer({ ...attributes }) {
    const context = useMemo(() => new BlockContext(attributes, Block, __experimentalChildrenBlockRenderer), [
      Block,
      attributes,
    ]);
    const Provider = BlockContext.Context.Provider;
    return (
      <Provider value={context}>
        <Block {...attributes} />
      </Provider>
    );
  };
}

export function RawViewer({ children, __experimentalChildrenBlockRenderer }) {
  const context = useMemo(() => new BlockContext({}, null, __experimentalChildrenBlockRenderer), [
    __experimentalChildrenBlockRenderer
  ]);
  const Provider = BlockContext.Context.Provider;
  return (
      <Provider value={context}>
        {children}
      </Provider>
  );
};
// This was an idea, but it need more digging
// export function BlockStatic({block: Block, attributes}){
//   const context = useMemo(() => new BlockContext(attributes, Block), [
//     Block,
//     attributes,
//   ]);
//   const Provider = BlockContext.Context.Provider;
//   return (
//       <Provider value={context}>
//         <Block {...attributes} />
//       </Provider>
//   );
// }
