import React from 'react';
import { toKebabCase, toPascalCase } from './utils';
import { ClassicBlockWrapper } from './BlockEditor';
import defineBlock from './defineBlock';

/**
 * Method allowing to retrieve a block definition.
 * The block must at least be a React Component
 *
 * @callback registerBlockProvider
 * @return {React.ComponentType<any>|Promise<any>}
 */

/**
 * Register a Block type as a Gutenberg component.
 * This force registration of any block as a Smartfire block available in the page.
 * This function is handled by the compilation magic, but you can use yourself to directly register blocks.
 *
 * @param {string|registerBlockProvider} name the component identifier
 * @param {registerBlockProvider} component
 * @param {string} vendor
 */
export default function registerBlock(name, component, vendor = "smartfire") {
  if (typeof name !== 'string') {
    component = Promise.resolve(name);
    name = name.name;
  }
  window.smartfire = Object.assign(window.smartfire || {}, {});
  const extender = {};
  extender[name] = () => {
    return Promise.resolve(component()).then(async blockModule => {
      let block = blockModule.default || blockModule;
      const blockApi = await import('@wordpress/blocks');
      const componentName = vendor +'/' + toKebabCase(name);
      if (
        typeof blockApi.registerBlockType === 'function' &&
        blockApi.getBlockTypes().filter(t => t.name === componentName).length <=
          0
      ) {
        if (block.classSupport) {
          // Support for old generation class blocks
          block = defineBlock({
            Block: ClassicBlockWrapper(block),
            smartProps: block.smartProps,
            setup: ClassicBlockWrapper.extractSetup(block),
          });
        }
        if (block._smartfire) {
          const setup = block._smartfire.configureGutenberg(name);
          blockApi.registerBlockType(componentName, setup);
        }
      }

      return block;
    });
  };
  extender[toPascalCase(name)] = extender[name];
  extender[toKebabCase(name)] = extender[name];
  window.smartfire.components = Object.assign(
    window.smartfire.components || {},
    extender
  );
}
