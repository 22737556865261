import React from 'react';
import { smartfireIcon } from './icon';

export class BlockSetup {
    /**
     * Human name of the component to display in the list
     * @type {null|string}
     */
    title: null | string = null;

    /**
     * Icon to illustrate your component.
     * See Dashicons for default provided icons.
     * @type {string|React.Component}
     */
    icon = React.createElement(smartfireIcon, { color: 'unset' });

    /**
     * This is a short description for your block, which can be translated.
     * @type {null|string}
     */
    description?: string;

    /**
     * Blocks are grouped into categories to help users browse and discover them.
     * Defaults ones: common | formatting | layout | widgets | embed | smartfire
     * @type {string}
     */
    category: 'common' | 'formatting' | 'layout' | 'widgets' | 'embed' | 'smartform' | 'smartfire' = 'smartfire';

    /**
     * Sometimes a block could have aliases that help users discover it while searching.
     * This is translatable.
     * @type {string[]}
     */
    keywords: string[] = [];

    /**
     * Example provides structured example data for the block. This data is used to construct
     * a preview for the block to be shown in the Inspector Help Panel when the user mouses
     * over the block.
     * @type {null|{}|{attributes: Map<string, *>}}
     */
    example?: any;

    /**
     * Setup transform rules from this block to other blocks.
     * See: https://developer.wordpress.org/block-editor/developers/block-api/block-registration/#transforms-optional
     * @type {{from: [*], to: [*]}}
     */
    transforms?: {
        from: any[],
        to: any[],
    };

    /**
     * Blocks are able to be inserted into blocks that use InnerBlocks as nested content.
     * Sometimes it is useful to restrict a block so that it is only available as a nested block.
     * For example, you might want to allow an ‘Add to Cart’ block to only be available within a ‘Product’ block.
     *
     * Assigning it as empty array will disable block from backend editor.
     *
     * @type {string[]|null}
     *
     */
    parent?: string[];

    /**
     * Settings for the wordpress environment.
     * See: https://developer.wordpress.org/block-editor/developers/block-api/block-registration/#supports-optional
     */
    supports: {} = {
        alignWide: true,
        anchor: false,
        align: false,
        customClassName: false,
        className: true,
        html: false,
        inserter: true,
        multiple: true,
        reusable: false,
    };

    /**
     * Attributes provide the structured data needs of a block.
     *
     * They can exist in different forms when they are serialized,
     * but they are declared together under a common interface.
     *
     * Prefer to use the smartProps support instead of this one.
     *
     * @see https://developer.wordpress.org/block-editor/developers/block-api/block-attributes/
     */
    attributes: any = {};
}

export interface Block<T extends {}> {
    Block: React.FunctionComponent<T> | React.ComponentClass<T>;
    smartProps?: any,
    setup?: Partial<BlockSetup>
}
