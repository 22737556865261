import React from 'react';

export const setupWordpressSupport = () => {
  import('@wordpress/blocks').then(wpBlocks => {
    if (typeof wpBlocks.getCategories === 'function') {
      wpBlocks
        .getCategories()
        .unshift({
          slug: 'smartfire',
          title: 'Smartfire Blocks',
          icon: <></>,
        }, {
          slug: 'smartform',
          title: 'Formulaires',
          icon: <></>
        });

    }
  });
};
