import '@wordpress/format-library';
import '@wordpress/core-data';
import React from 'react';
import * as blockApi from '@wordpress/blocks';
import type Smartfire from './Smartfire';

type Block = blockApi.Block;
type CoreBlock = {
    metadata: blockApi.Block,
    name: string,
    settings: blockApi.Block
};
declare global {
    interface Window {
        __hasRegisteredCoreBlocks: boolean | undefined;
        wp: {
            blocks: typeof blockApi
            blockLibrary: {registerCoreBlocks: (blocks?: Block[]) => void, __experimentalGetCoreBlocks: () => CoreBlock[]}
        },
        smartfire: typeof Smartfire
    }
}

const isSupported = (block: CoreBlock) => {
    if(!block) return false;
    switch (block.metadata.category) {
        case "text":
            return true;
        case "media":
            if(block.name === "core/image") {
                // @ts-ignore attribute id does exists on the core/image type
                block.metadata.attributes.id.type = "string";
            }
            switch (block.name) {
                case "core/gallery":
                case "core/media-text":
                case "core/cover":
                    return false;
            }
            return true;
        case "design":
            return true;
    }
    return false;
}

const getGlueBlocks = () => {
    if(window.wp && window.wp.blockLibrary && window.wp.blockLibrary.__experimentalGetCoreBlocks) {
        let blocks = window.wp.blockLibrary.__experimentalGetCoreBlocks();
        return blocks.filter(isSupported);
    } else {
        return [];
    }
}

export const registerBlocks = () => {
    window.__hasRegisteredCoreBlocks =
        window.__hasRegisteredCoreBlocks || false;
    const canRegister = window.wp && window.wp.blockLibrary && window.wp.blockLibrary.__experimentalGetCoreBlocks;
    if (!window.__hasRegisteredCoreBlocks && canRegister) {
        (getGlueBlocks()).forEach(b => {
            window.wp.blocks.registerBlockType(b.name, {...b.metadata, ...b.settings});
        });
        window.wp.blocks.setDefaultBlockName("core/paragraph");
        window.wp.blocks.setGroupingBlockName("core/group");
        window.__hasRegisteredCoreBlocks = true;
    }
};