(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@wordpress/components"), require("@wordpress/block-editor"), require("@wordpress/blocks"), require("react-dom"), require("@wordpress/editor"), require("lodash"), require("@wordpress/block-serialization-default-parser"), require("@wordpress/i18n"), require("@wordpress/format-library"), require("@wordpress/core-data"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@wordpress/components", "@wordpress/block-editor", "@wordpress/blocks", "react-dom", "@wordpress/editor", "lodash", "@wordpress/block-serialization-default-parser", "@wordpress/i18n", "@wordpress/format-library", "@wordpress/core-data"], factory);
	else if(typeof exports === 'object')
		exports["smartfireGutenberg"] = factory(require("react"), require("@wordpress/components"), require("@wordpress/block-editor"), require("@wordpress/blocks"), require("react-dom"), require("@wordpress/editor"), require("lodash"), require("@wordpress/block-serialization-default-parser"), require("@wordpress/i18n"), require("@wordpress/format-library"), require("@wordpress/core-data"));
	else
		root["smartfireGutenberg"] = factory(root["React"], root["wp"]["components"], root["wp"]["blockEditor"], root["wp"]["blocks"], root["ReactDOM"], root["wp"]["editor"], root["lodash"], root["wp"]["blockSerializationDefaultParser"], root["wp"]["i18n"], root["wp"]["formatLibrary"], root["wp"]["coreData"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__27__, __WEBPACK_EXTERNAL_MODULE__55__, __WEBPACK_EXTERNAL_MODULE__62__, __WEBPACK_EXTERNAL_MODULE__118__, __WEBPACK_EXTERNAL_MODULE__120__, __WEBPACK_EXTERNAL_MODULE__122__, __WEBPACK_EXTERNAL_MODULE__123__) {
return 