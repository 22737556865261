import React, {useEffect} from "react";
import {defineBlock, smartProps, useEditor, useSmartProp, useInspectorControl, EditableText} from "../..";
import { PanelBody, PanelRow, ToggleControl, SelectControl, TextControl  } from '@wordpress/components';

function Inspector(){
    const keysForms = window.keysFields;
    const currentFormId = window.currentIdForm;
    const keys = keysForms.filter(form => form.value === currentFormId)[0];
    const keysTextarea = keys.textarea;

    const [name, setName] = useSmartProp('name');
    const [required, setRequired] = useSmartProp("required");
    const [placeholder, setPlaceholder] = useSmartProp('placeholder');

    useEffect(() => {
        if (!name) setName(keysTextarea[0].value);
    });

    return <PanelBody title="Options" initialOpen={true}>
        <PanelRow>
            <ToggleControl
                label="Required"
                checked={required}
                onChange={setRequired}
            />
        </PanelRow>
        <PanelRow>
            <TextControl
                label="Placeholder"
                value={placeholder}
                onChange={setPlaceholder}
            />
        </PanelRow>
        <PanelRow>
            <SelectControl
                label="Name"
                value={name}
                options={keysTextarea}
                onChange={setName}
            />
        </PanelRow>
    </PanelBody>
}

function Block({label, required, placeholder, name}) {
    useInspectorControl(Inspector);
    const { isEditing } = useEditor();

    return <div>
        { isEditing ?
            <EditableText tagName="label" placeholder="Label" smartProp="label" /> :
            <label htmlFor={name} dangerouslySetInnerHTML={{__html: label}}></label>
        }
        <textarea placeholder={placeholder} name={name} required={required} id={name} rows={5}></textarea>
    </div>
}

export default defineBlock({
    Block,
    smartProps: {
        label: smartProps.string.withDefault('label'),
        required: smartProps.bool.withDefault(false),
        name: smartProps.string.withDefault('message'),
        placeholder: smartProps.string.withDefault('placeholder'),
    },
    setup: {
        title: "Textarea",
    }
});
