import React from 'react';
import * as style from './fallback-block.module.scss';
import { smartfireIcon as Icon } from '../../icon';

export default ({ name }) => {
  return (
    <div className={style.fallback}>
      <div>
        <Icon />
      </div>
      <div>
        Component {name} is not defined yet, you can create it under
        "application/src/{name}/block.js"
      </div>
    </div>
  );
};
