import React, {useState} from 'react';

import style from './style.module.scss';

import {
  ColorPalette,
  Toolbar,
  IconButton,
  Popover,
} from '@wordpress/components';

export const classNames = style;

export default function ColorToolbarButton({ color, colors, setColor }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <Toolbar aria-expanded={isOpen}>
      <IconButton
        icon="admin-appearance"
        title="Couleur"
        onClick={() => {
          setOpen(!isOpen);
        }}
      />
      {isOpen && (
        <Popover
          className={style.colorPickerPopover}
          onClose={() => setOpen(false)}
          position={'bottom'}
        >
          <ColorPalette
            colors={colors}
            value={color}
            onChange={setColor}
            disableCustomColors={true}
            clearable={false}
            className={style.colorPicker}
          />
        </Popover>
      )}
    </Toolbar>
  );
}
